import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from 'rebass/styled-components'

const SectionTitle = ({ children, as, sx, ...rest }) => (
  <Heading
    as={as}
    {...rest}
    sx={{
      marginBottom: '1.5rem',
      color: rest.white ? '#FFFFFF' : 'primary',
      fontSize: '2rem',
      fontWeight: 'heading',
      textAlign: 'center',
      ...sx,
    }}
  >
    {children}
  </Heading>
)

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  sx: PropTypes.object,
}

export default SectionTitle
