import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { Box } from 'rebass/styled-components'

const Section = ({ children, ...rest }) => (
  <Box as="section" variant="section" {...rest}>
    {children}
  </Box>
)

export const LightSection = styled(Section)`
  background-color: #fbfbff;
`

const customLinearGradient = css`
  background: linear-gradient(
    45deg,
    ${(props) => darken(0.4, props.color)},
    ${(props) => props.color}
  );
`

export const GradientSection = styled(Section)`
  ${(props) =>
    props.color
      ? customLinearGradient
      : `background: ${props.theme.gradients.primary}`};
  color: #ffffff;
`

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
